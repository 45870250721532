<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title class="text-uppercase text-primary font-weight-bolder">
        Tổng quan
      </b-card-title>
      <b-dropdown
        v-model="apartment"
        :text="apartment ? apartment.name : 'Tất cả nhà'"
        variant="transparent"
        class="chart-dropdown border px-1 py-50"
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="eachApartment in data"
          :key="eachApartment.id"
          :value="eachApartment"
          @click="apartment = eachApartment"
        >
          {{ eachApartment.name }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>

      <!-- apex chart -->
      <vue-apex-charts
        v-if="apartment !== null && apartment.totalSlots > 0"
        type="pie"
        height="256"
        class="mt-2 mb-1"
        :options="customersPie.chartOptions"
        :series="getSeries"
      />

      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(eachData, index) in getCurrentData"
          :key="index"
          class="d-flex justify-content-between"
          :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
        >
          <div class="series-info">
            <feather-icon
              :icon="eachData.icon"
              size="16"
              class="mr-50"
              :class="eachData.iconColor"
            />

            <span class="font-weight-bolder ml-75 mr-25">{{ eachData.text }}</span><span>- {{ eachData.percentage }}%</span>
          </div>
          <span>{{ eachData.result }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody,
} from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import { $themeColors } from '@themeConfig';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  data() {
    return {
      data: [],
      apartment: null,
      chartData: {
        listData: [
          {
            icon: 'CircleIcon',
            iconColor: 'text-primary',
            text: 'Đang thuê',
            result: 890,
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-warning',
            text: 'Đang trống',
            result: 258,
          },
          {
            icon: 'CircleIcon',
            iconColor: 'text-danger',
            text: 'Đang cọc',
            result: 149,
          },
        ],
      },
      customersPie: {
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Đang thuê', 'Đang trống', 'Đang cọc'],
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          stroke: {
            width: 1,
          },
          colors: [$themeColors.primary, $themeColors.danger, $themeColors.warning],
        },
      },
    };
  },
  computed: {
    getCurrentData() {
      if (this.apartment === null) {
        return [];
      }
      return [
        {
          icon: 'CircleIcon',
          iconColor: 'text-primary',
          text: 'Đang thuê',
          result: this.apartment.totalLivingSlots,
          percentage: this.apartment.totalSlots > 0 ? ((this.apartment.totalLivingSlots * 100) / this.apartment.totalSlots).toFixed(2) : 0,
        },
        {
          icon: 'CircleIcon',
          iconColor: 'text-danger',
          text: 'Đang trống',
          result: this.apartment.totalEmptySlots,
          percentage: this.apartment.totalSlots > 0 ? ((this.apartment.totalEmptySlots * 100) / this.apartment.totalSlots).toFixed(2) : 0,
        },
        {
          icon: 'CircleIcon',
          iconColor: 'text-info',
          text: 'Đang cọc',
          result: this.apartment.totalDepositSlots,
          percentage: this.apartment.totalSlots > 0 ? ((this.apartment.totalDepositSlots * 100) / this.apartment.totalSlots).toFixed(2) : 0,
        },
      ];
    },
    getSeries() {
      if (this.apartment === null) {
        return [];
      }
      return [this.apartment.totalLivingSlots, this.apartment.totalEmptySlots, this.apartment.totalDepositSlots];
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      useJwt.getApartmentOccupancyRateAnalytics().then(response => {
        this.data = response.data;
        this.apartment = this.data && this.data.length > 0 ? this.data[0] : null;
      });
    },
    onSelectApartment(option, value) {

    },
  },
};
</script>
