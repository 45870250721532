<template>
  <b-card
    v-if="!isLoading"
    class="card-congratulation-medal"
    bg-variant="primary"
    text-variant="white"
  >
    <h5 class="text-white font-weight-bolder">
      {{ title }}
    </h5>
    <b-card-text class="font-small-3 mr-4">
      {{ description }}
    </b-card-text>
    <b-button
      variant="warning"
      size="md"
      class="font-small-3"
    >

      Xem chi tiết

      <feather-icon icon="ChevronsRightIcon" />
    </b-button>
    <div
      v-if="bar"
      class="pt-2 mt-2"
    >

      <dl class="row mb-0">
        <dt class="col-sm-6 text-left font-small-3">
          {{ bar.title }}: {{ bar.value }}/{{ bar.total }}
        </dt>
        <dd class="col-sm-6 text-right font-small-3">
          {{ bar.percentage }}%
        </dd>
      </dl>

      <b-progress
        animated
        :value="bar.percentage"
        :variant="bar.variant"
        class="progress-bar-danger"
      />
    </div>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />

  </b-card>
</template>

<script>
import {
  BCard, BCardText, BImg, BProgress, BButton,
} from 'bootstrap-vue';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BCard,
    BCardText,
    BImg,
    BProgress,
    BButton,
  },

  props: {
    apartment: {
      type: [Object, null],
      default: null,
    },
  },
  data: () => ({
    bars: [

    ],
    bar: null,
    index: 0,
    timer: null,
    title: '',
    description: '',
    isLoading: false,

  }),
  watch: {
    apartment() {
      this.fetchData(this.apartment);
    },
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  created() {
    this.fetchData(null);
  },
  methods: {
    fetchData(apartment) {
      this.isLoading = true;
      useJwt.getReportPropertyGeneralData(apartment).then(response => {
        this.isLoading = false;
        this.bars = [];
        if (response.data.totalEmptySlots > 0) {
          this.bars.push({
            variant: 'danger', value: response.data.totalEmptySlots, title: 'Trống', total: response.data.totalSlots, percentage: response.data.emptyPercentage,
          });
        }
        if (response.data.totalDepositSlots > 0) {
          this.bars.push({
            variant: 'secondary', value: response.data.totalDepositSlots, title: 'Đang cọc', total: response.data.totalSlots, percentage: response.data.depositPercentage,
          });
        }
        if (response.data.totalLivingSlots > 0) {
          this.bars.push({
            variant: 'warning', value: response.data.totalLivingSlots, title: 'Đang ở', total: response.data.totalSlots, percentage: response.data.livingPercentage,
          });
        }

        this.title = response.data.title;
        this.description = response.data.description;
        if (this.bars.length > this.index) {
          this.bar = this.bars[this.index];
          this.timer = setInterval(() => {
            if (this.index >= this.bars.length) {
              this.index = 0;
            }
            this.bar = this.bars[this.index];
            this.index += 1;
          }, 3000);
        }
      });
    },
  },
};
</script>
