<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="text-uppercase text-primary font-weight-bolder">
        Tổng quan
      </b-card-title>
    </b-card-header>
    <b-card-body>

      <b-row class="mt-2">
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: false,
            }"
            :select-options="{
              enabled: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Code -->
              <span v-if="props.column.type === 'number'">
                {{ Number(props.row[props.column.field]).toLocaleString() }} ({{ ((props.row[props.column.field] * 100) / props.row.totalSlots).toFixed(0) || 0 }} %)
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Hiển thị tối đa </span>
                  <b-form-select
                    v-model="serverParams.perPage"
                    :options="['10', '20', '50', '100', '500']"
                    class="mx-1"
                    @input="
                      (value) =>
                        props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    trên tổng số {{ props.total }} kết quả
                  </span>
                </div>

              </div>
            </template>
            <div slot="emptystate">
              <div class="text-center text-muted">
                Không có bản ghi nào!
              </div>
            </div>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCol, BRow,
} from 'bootstrap-vue';
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCol,
    BRow,
    VueGoodTable,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const columns = [
      {
        label: 'Tòa nhà',
        field: 'name',
      },
      {
        label: 'Tổng cộng',
        field: 'totalSlots',
        type: 'number',
        width: '120px',
      },
      {
        label: 'Đang thuê',
        field: 'totalLivingSlots',
        type: 'number',
        width: '120px',
      },
      {
        label: 'Đang cọc',
        field: 'totalDepositSlots',
        type: 'number',
        width: '120px',
      },
      {
        label: 'Đang trống',
        field: 'totalEmptySlots',
        type: 'number',
        width: '120px',
      },
    ];
    return { columns };
  },
};
</script>
